<template>
   <ObiText class="obi-input" :class="{ 'is-invalid': errors.length }">
      <label :for="id || defaultId" v-html="label" v-if="label" class="form-label" :class="labelClass" />
      <ObiText class="input-group" :class="[{ 'mb-3': !compact, 'input-group-clean': clean }, wrapperClass]">
         <div class="obi-input-icon input-group-text input-group-icon" v-if="icon && !iconRight">
            <ObiIcon :icon="icon" />
         </div>

         <input
            :type="type"
            v-bind="attrs"
            class="form-control"
            :id="id || defaultId"
            :class="{ 'is-invalid': errors.length }"
            v-model="inputValue"
            @change="onChanged"
         />

         <div class="obi-input-icon input-group-text input-group-icon" v-if="icon && iconRight">
            <ObiIcon :icon="icon" />
         </div>
      </ObiText>

      <ObiText class="invalid-feedback" v-if="errors.length">
         <ObiText class="invalid-feedback-line" v-for="(error, index) in errors" :key="index" v-html="error" />
      </ObiText>
   </ObiText>
</template>

<script>
import uuid from 'uuidjs';
import { get, keys, omit } from 'lodash';

export default {
   name: 'ObiInput',
   props: {
      value: {},
      id: {
         type: String,
         default: null,
      },
      type: {
         type: String,
         default: 'text',
      },
      label: {
         type: String,
         default: null,
      },
      icon: {
         type: [String, Object],
         default: null,
      },
      iconRight: {
         type: Boolean,
         default: false,
      },
      wrapperClass: {
         type: String,
         default: null,
      },
      labelClass: {
         type: String,
         default: null,
      },
      compact: {
         type: Boolean,
         default: false,
      },
      clean: {
         type: Boolean,
         default: false,
      },
      errors: {
         type: Array,
         default: () => [],
      },
   },
   beforeMount() {
      this.defaultId = get(uuid.genV4(), 'hexNoDelim');
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
   },
   data() {
      return {
         defaultId: null,
      };
   },
   methods: {
      onChanged() {
         this.$emit('change', this.inputValue);
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.obi-input {
   .form-label {
      color: $primary;
   }

   .form-control {
      line-height: 1;
      padding: 9px 20px;

      &[readonly] {
         background-color: transparent;
      }
   }

   &.is-invalid {
      .input-group-text {
         border-color: $form-feedback-invalid-color;
      }
   }

   &.is-valid {
      .input-group-text {
         border-color: $form-feedback-valid-color;
      }
   }

   .invalid-feedback {
      text-align: left;
   }

   .for-toolbar {
      .form-control,
      .input-group-text.input-group-icon {
         line-height: 1;
         padding: 8px 10px;
      }
   }
}
</style>
